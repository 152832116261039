import { ResponsiveBreak } from 'src/components';
import { Typography } from '@mui/material';
import { JobOpeningCrucialRequirementCategory } from 'src/types';

export const Subheader: React.FC<{
  category: JobOpeningCrucialRequirementCategory;
}> = ({ category }) => {
  if (
    [
      JobOpeningCrucialRequirementCategory.DomainKnowledge,
      JobOpeningCrucialRequirementCategory.Technology,
    ].includes(category)
  ) {
    return (
      <Typography>
        Some of our open positions <b>require experience</b> in these
        technologies <ResponsiveBreak breakpoint="xs" /> or domains. Please
        select which ones match your skill set.
      </Typography>
    );
  }

  if (JobOpeningCrucialRequirementCategory.Education === category) {
    return (
      <Typography>
        Some of our positions or clients{' '}
        <b>rrequire specific educational qualifications</b>.
        <ResponsiveBreak breakpoint="xs" />
        Could you please specify your educational background?
      </Typography>
    );
  }

  if (JobOpeningCrucialRequirementCategory.Visa === category) {
    return (
      <Typography>
        We are unable to sponsor or take over sponsorship{' '}
        <ResponsiveBreak breakpoint="xs" />
        of an employment visa at this time. Do you have one?
      </Typography>
    );
  }

  return null;
};
