import { useMemo } from 'react';
import { APP_ROUTES } from '../constants';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { useIsPage } from './useIsPage';

export const useIsCrucialRequirementsStepper = (): boolean => {
  const userData = useAppSelector(userSelectors.getUserData);
  const skillRequirementsPage = useIsPage(APP_ROUTES.SKILLS_REQUIREMENTS);
  const educationRequirementsPage = useIsPage(
    APP_ROUTES.EDUCATION_REQUIREMENTS,
  );
  const visaRequirementsPage = useIsPage(APP_ROUTES.VISA_REQUIREMENTS);

  const isQuestioneerStepper = useMemo(() => {
    if (!userData) return false;

    return (
      skillRequirementsPage || educationRequirementsPage || visaRequirementsPage
    );
  }, [userData]);

  return isQuestioneerStepper;
};
