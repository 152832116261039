import { Box } from '@mui/material';
import { RequirementItem } from './RequirementItem';

import { JobOpeningCrucialRequirementCategory } from '@types';

export const NONE_OPTION_VALUE = 'none';
export const NONE_OPTION_LABEL = 'None of the above';

export const RequirementsList: React.FC<{
  requirements: {
    name: string;
    category: JobOpeningCrucialRequirementCategory;
  }[];
  selectedRequirements: string[];
  handleChange: (
    category: JobOpeningCrucialRequirementCategory,
    value: string,
    isAdd: boolean,
  ) => void;
}> = ({ requirements, selectedRequirements, handleChange }) => {
  if (!requirements.length) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} flexWrap="wrap">
        {requirements.map((requirement) => (
          <RequirementItem
            key={requirement.name}
            category={requirement.category}
            label={requirement.name}
            value={requirement.name}
            isSelected={selectedRequirements.includes(requirement.name)}
            handleChange={handleChange}
          />
        ))}
      </Box>
      <Box display="flex">
        <RequirementItem
          category={requirements[0].category}
          label={NONE_OPTION_LABEL}
          value={NONE_OPTION_VALUE}
          isSelected={selectedRequirements.includes(NONE_OPTION_VALUE)}
          handleChange={handleChange}
        />
      </Box>
    </Box>
  );
};
