export enum JobOpeningCrucialRequirementPriority {
  NiceToHave = 'niceToHave',
  MustHave = 'mustHave',
}

export enum JobOpeningCrucialRequirementCategory {
  Education = 'education',
  Technology = 'technology',
  DomainKnowledge = 'domainKnowledge',
  Visa = 'visa',
}

export type JobOpeningCrucialRequirement = {
  name: string;
  priority: JobOpeningCrucialRequirementPriority;
  category: JobOpeningCrucialRequirementCategory;
};

export type CrucialRequirementsByJobOpeningId = Record<
  string,
  JobOpeningCrucialRequirement[]
>;

export interface ICrucialRequirementsBody {
  additionalSkills: string[];
  educations: string[];
  domains: string[];
  visas: string[];
}
