import { isEmpty, flatten } from 'lodash';
import {
  IUserData,
  JobOpeningCrucialRequirementCategory,
  JobOpeningCrucialRequirementPriority,
} from 'src/types';
import { isTruthy } from './general';
import { CrucialRequirementsCategoriesOrder } from 'src/constants/crucialRequirements';

export const getUserMissingRequirementsByCategory = (
  user: IUserData | null,
  onlyRequired = false,
): Record<JobOpeningCrucialRequirementCategory, string[]> => {
  if (!user || !user.crucialRequirements || isEmpty(user.crucialRequirements))
    return {
      [JobOpeningCrucialRequirementCategory.DomainKnowledge]: [],
      [JobOpeningCrucialRequirementCategory.Technology]: [],
      [JobOpeningCrucialRequirementCategory.Education]: [],
      [JobOpeningCrucialRequirementCategory.Visa]: [],
    };

  const userData = ([] as Array<string | null>)
    .concat(user.primarySkills)
    .concat(user.secondarySkills)
    .concat(user.skillset)
    .concat(user.additionalSkills)
    .concat(user.domains)
    .concat(user.educations)
    .concat(user.visas)
    .filter(isTruthy);

  const missingRequirementsByCategory = flatten(
    Object.values(user.crucialRequirements),
  ).reduce(
    (acc, requirement) => {
      if (
        userData.includes(requirement.name) ||
        (requirement.priority ===
          JobOpeningCrucialRequirementPriority.NiceToHave &&
          onlyRequired)
      )
        return acc;

      return {
        ...acc,
        [requirement.category]: acc[requirement.category].concat(
          requirement.name,
        ),
      };
    },
    {
      [JobOpeningCrucialRequirementCategory.DomainKnowledge]: [],
      [JobOpeningCrucialRequirementCategory.Technology]: [],
      [JobOpeningCrucialRequirementCategory.Education]: [],
      [JobOpeningCrucialRequirementCategory.Visa]: [],
    } as Record<JobOpeningCrucialRequirementCategory, string[]>,
  );

  return missingRequirementsByCategory;
};

export const getUserMissingRequirementCatagories = (
  user: IUserData | null,
): JobOpeningCrucialRequirementCategory[] => {
  if (!user || !user.crucialRequirements || isEmpty(user.crucialRequirements))
    return [];

  const missingRequirementsByCategory =
    getUserMissingRequirementsByCategory(user);

  return (
    Object.keys(
      missingRequirementsByCategory,
    ) as JobOpeningCrucialRequirementCategory[]
  )
    .filter((category) => missingRequirementsByCategory[category].length)
    .sort(
      (a, b) =>
        CrucialRequirementsCategoriesOrder.indexOf(a) -
        CrucialRequirementsCategoriesOrder.indexOf(b),
    );
};
