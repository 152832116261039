import { API_STATUS } from '../../constants';
import { IUserData } from '../../types';
import { IUserSliceState } from './user.slice';
import { userStoreKey } from './user.const';

interface IState {
  [userStoreKey]: IUserSliceState;
}

const selectUserState = (state: IState) => {
  return state[userStoreKey];
};

export const getUserApiStatus = (state: IState): API_STATUS => {
  return selectUserState(state).apiStatus;
};

export const getUserData = (state: IState): IUserData | null => {
  return selectUserState(state).userData;
};

export const getUserId = (state: IState): string | null => {
  return selectUserState(state)?.userData?.id ?? null;
};

export const getIsUserPerformingAction = (state: IState): boolean => {
  return selectUserState(state).isPerformingAction;
};

export const getUserCrucialRequirements = (state: IState) => {
  return selectUserState(state).crucialRequirements;
};
