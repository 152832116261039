import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import { Box } from '@mui/material';
import { ISubNavigationStep } from '../../../../types';
import { isKeyClick } from '../../../../utils';
import { StepIcon, StepLabel } from '../../../../components';

interface IProps {
  step: ISubNavigationStep;
  handleEditStep: (
    event: KeyboardEvent | MouseEvent,
    step: ISubNavigationStep,
  ) => void;
}

const QuestioneerStep: FC<IProps> = ({ step, handleEditStep }) => {
  const { route, label, isActive, isHeader, isEditable, isCompleted } = step;
  const handleClick = (event: KeyboardEvent | MouseEvent) => {
    handleEditStep(event, step);
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: isHeader ? undefined : 'pointer',
        textDecorationThickness: '0.5px',
        textUnderlineOffset: '20%',
        pointerEvents: isEditable ? undefined : 'none',
        position: 'relative',
        pl: 3.2,
        mb: isHeader ? 3 : 0,
      }}
      data-testid={`edit-${label.replaceAll(' ', '-')}`}
      onKeyUp={(e: KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          e.stopPropagation();
          handleClick(e);
        }
      }}
      role={route && isEditable ? 'button' : ''}
      tabIndex={route && isEditable ? 0 : -1}
    >
      {isHeader && <StepIcon isActive={isActive} isCompleted={isCompleted} />}
      <StepLabel step={step} showEditIcon />
    </Box>
  );
};

export default QuestioneerStep;
