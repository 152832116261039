import { Countries } from 'src/constants';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';

export const useIsDisqualified = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const isNotFitPerson = [
    'DisQualified: Bad personality',
    'Bad experience with AE',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedProgramingLanguage = [
    'DisQualified: Not our stack',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByEnglishLevel = [
    'DisQualified: Low level of English',
    'No IC: VI - low English',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByLegalRestrictions = [
    'DisQualified: Legal restrictions',
    'DisQualified: Accounting issues',
  ].includes(userData?.leadClosedBecause || '');
  const isNotAuthorizedToWorkInUs =
    isNotSupportedByLegalRestrictions &&
    userData?.country === Countries.UnitedStates &&
    userData.notAuthorizedToWorkInUs;
  const isRequireVisaSponsorship =
    isNotSupportedByLegalRestrictions &&
    userData?.country === Countries.UnitedStates &&
    userData.requireVisaSponsorship;
  const isNotSupportedByLocation = [
    'DisQualified: Not a fit by location',
    'DisQualified: Relocation only',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByCompensation = [
    'DisQualified: Money related',
    'High salary expectations',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByOpenPositionsSeniority = [
    'No suitable openings: seniority',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByOpenPositionLocation = [
    'No suitable openings: location',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByOpenPositionsCoreStack = [
    'No suitable openings: Core stack',
    'No suitable openings: Not our core stack',
    'DisQualified: No suitable openings for Back-office',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByOpenPositionsCrucialRequirements = [
    'No suitable openings: Crucial requirements',
  ].includes(userData?.leadClosedBecause || '');
  const isNotSupportedByOpenPositions =
    isNotSupportedByOpenPositionsSeniority ||
    isNotSupportedByOpenPositionLocation ||
    isNotSupportedByOpenPositionsCoreStack ||
    isNotSupportedByOpenPositionsCrucialRequirements;
  const isNonHireable = [
    'Non-hireable',
    'Disqualified: Non-hireable ex-contractor',
  ].includes(userData?.leadClosedBecause || '');
  const isRejectedOffer = [
    'DisQualified: rejected offer after acceptance',
  ].includes(userData?.leadClosedBecause || '');
  const isHasNotStartedWorking = [
    'DisQualified: hasn’t started working after offer acceptance',
  ].includes(userData?.leadClosedBecause || '');
  const isNotFitBySoftSkills = ['Not a fit by soft skills'].includes(
    userData?.leadClosedBecause || '',
  );
  const isFailedTestTask = [
    'Test Task failed',
    'Quiz failed',
    'Disqualified: Cheated on Test Task',
  ].includes(userData?.leadClosedBecause || '');
  const isFailedInterview = [
    'Tech Interview failed',
    'Client Interview failed',
    'Rejected by TL',
  ].includes(userData?.leadClosedBecause || '');
  const isVIFailed = [
    'Video Interview failed',
    'Disqualified: Cheated on Video Interview',
  ].includes(userData?.leadClosedBecause || '');
  const isOtherDisqualifyReason =
    userData?.leadClosedBecause &&
    !(
      isNotFitPerson ||
      isNotSupportedProgramingLanguage ||
      isNotSupportedByEnglishLevel ||
      isNotSupportedByLegalRestrictions ||
      isNotSupportedByLocation ||
      isNotSupportedByCompensation ||
      isNotSupportedByOpenPositions ||
      isNonHireable ||
      isRejectedOffer ||
      isHasNotStartedWorking ||
      isNotFitBySoftSkills ||
      isFailedTestTask ||
      isFailedInterview ||
      isVIFailed
    );

  const isDisqualified = !!userData?.closedAt;

  return {
    isDisqualified,
    isNotFitPerson,
    isNotSupportedProgramingLanguage,
    isNotSupportedByEnglishLevel,
    isNotSupportedByLegalRestrictions,
    isRequireVisaSponsorship,
    isNotAuthorizedToWorkInUs,
    isNotSupportedByLocation,
    isNotSupportedByCompensation,
    isNotSupportedByOpenPositions,
    isNonHireable,
    isRejectedOffer,
    isHasNotStartedWorking,
    isNotFitBySoftSkills,
    isFailedTestTask,
    isFailedInterview,
    isOtherDisqualifyReason,
    isNotSupportedByOpenPositionsSeniority,
    isNotSupportedByOpenPositionLocation,
    isNotSupportedByOpenPositionsCoreStack,
    isNotSupportedByOpenPositionsCrucialRequirements,
    isVIFailed,
  };
};
