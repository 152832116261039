import { JobOpeningCrucialRequirementCategory } from 'src/types';
import { APP_ROUTES } from './appRoutes';

export const CrucialRequirementsCategoriesOrder: JobOpeningCrucialRequirementCategory[] =
  [
    JobOpeningCrucialRequirementCategory.DomainKnowledge,
    JobOpeningCrucialRequirementCategory.Technology,
    JobOpeningCrucialRequirementCategory.Education,
    JobOpeningCrucialRequirementCategory.Visa,
  ];

export const AppRouteByRequirementCategory: Record<
  JobOpeningCrucialRequirementCategory,
  string
> = {
  [JobOpeningCrucialRequirementCategory.DomainKnowledge]:
    APP_ROUTES.SKILLS_REQUIREMENTS,
  [JobOpeningCrucialRequirementCategory.Technology]:
    APP_ROUTES.SKILLS_REQUIREMENTS,
  [JobOpeningCrucialRequirementCategory.Education]:
    APP_ROUTES.EDUCATION_REQUIREMENTS,
  [JobOpeningCrucialRequirementCategory.Visa]: APP_ROUTES.VISA_REQUIREMENTS,
};
