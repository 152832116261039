/** @jsxImportSource @emotion/react */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux';

import { Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  PageTransitionWrapper,
  ResponsiveBreak,
  StepPanel,
  StepPanelHeader,
} from '../../components';
import startImage from '../../assets/passing-star.png';

import { confirmIntermediateScreen, userSelectors } from '../../redux/user';

export const FirstStepIsDone: React.FC = () => {
  const dispatch = useAppDispatch();

  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  const handleClick = () => {
    dispatch(confirmIntermediateScreen());
  };

  return (
    <PageTransitionWrapper>
      <StepPanel alignItems="center" textAlign="center">
        <StepPanelHeader
          srcSetPng={startImage}
          alt="Star"
          data-testid="success-title"
        >
          First step is done,
          <ResponsiveBreak breakpoint="sm" />
          let's get to know you better
        </StepPanelHeader>
        <Typography
          variant="body1"
          mb={0}
          data-testid="not-supported-by-open-positions-text"
        >
          To make sure we’ll not miss your talent our team would like
          <ResponsiveBreak breakpoint="sm" />
          to invite you to ask you for a quick introduction and a short test.
        </Typography>
        <Flex width={{ xs: '100%', sm: 'auto' }}>
          <CustomButton
            onClick={handleClick}
            label="Continue"
            color="primary"
            isLoading={confirmIntermediateScreenLoading}
            fullWidth
          />
        </Flex>
      </StepPanel>
    </PageTransitionWrapper>
  );
};
