import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import {
  useQuestioneerNavigationSteps,
  useMainNavigationSteps,
  useIsQuestioneerStepper,
  useIsMainStepper,
  useIsQuiz,
  useQuizNavigationSteps,
  useIsIndependentVIPage,
  useIsCrucialRequirementsStepper,
  useCrucialRequirementsNavigationSteps,
} from '../../hooks';
import QuestioneerStepper from './components/QuestioneerStepper/QuestioneerStepper';
import MainStepper from './components/MainStepper/MainStepper';
import QuizStepper from './components/QuizStepper/QuizStepper';

interface IProps {
  handleToggleSidebar?: (event: KeyboardEvent | MouseEvent) => void;
}

const Navigation: FC<IProps> = ({ handleToggleSidebar }) => {
  const isQuestioneerStepper = useIsQuestioneerStepper();
  const isMainStepper = useIsMainStepper();
  const isQuizStepper = useIsQuiz();
  const iCrucialRequirementsStepper = useIsCrucialRequirementsStepper();
  const isIndependentVIPage = useIsIndependentVIPage();
  const { navigationSteps } = useQuestioneerNavigationSteps();
  const { mainNavigationSteps } = useMainNavigationSteps();
  const { quizNavigationSteps } = useQuizNavigationSteps();
  const { crucialRequirementSteps } = useCrucialRequirementsNavigationSteps();

  if (isIndependentVIPage) {
    return <MainStepper steps={mainNavigationSteps} />;
  }

  return (
    <>
      {isQuizStepper && <QuizStepper steps={quizNavigationSteps} />}
      {isMainStepper && <MainStepper steps={mainNavigationSteps} />}
      {isQuestioneerStepper && (
        <QuestioneerStepper
          steps={navigationSteps}
          handleToggleSidebar={handleToggleSidebar}
        />
      )}
      {iCrucialRequirementsStepper && (
        <QuestioneerStepper
          steps={crucialRequirementSteps}
          handleToggleSidebar={handleToggleSidebar}
        />
      )}
    </>
  );
};

export default Navigation;
