import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SvgIcon } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { isKeyClick } from 'src/utils';
import { JobOpeningCrucialRequirementCategory } from 'src/types';
import { AppRouteByRequirementCategory } from 'src/constants';

export const BackButton: React.FC<{
  category: JobOpeningCrucialRequirementCategory;
  missingCrucialRequirementCategories: JobOpeningCrucialRequirementCategory[];
}> = ({ category, missingCrucialRequirementCategories }) => {
  const navigate = useNavigate();

  const hasPreviousStep =
    missingCrucialRequirementCategories[0] !== category &&
    category !== JobOpeningCrucialRequirementCategory.Technology;

  const onClick = useCallback(() => {
    const nextCategoryIdx =
      missingCrucialRequirementCategories.findIndex(
        (reqCategory) => reqCategory === category,
      ) - 1;
    const nextCategory = missingCrucialRequirementCategories[nextCategoryIdx];

    nextCategory && navigate(`/${AppRouteByRequirementCategory[nextCategory]}`);
  }, [category, missingCrucialRequirementCategories]);

  return (
    <SvgIcon
      inheritViewBox
      component={ArrowBackRoundedIcon}
      sx={(theme) => ({
        color: hasPreviousStep ? 'brand.accent' : 'text.secondary',
        ml: -6,
        mr: 3,
        mt: 1,
        cursor: hasPreviousStep ? 'pointer' : 'initial',
        transition: '500ms',
        ':hover': {
          color: hasPreviousStep ? 'text.primary' : 'text.secondary',
        },
        [theme.breakpoints.between('md', 'lg')]: {
          ml: -4,
          mr: 1,
        },
        [theme.breakpoints.down('md')]: {
          ml: -1,
          mr: 1,
          mt: 0.25,
        },
      })}
      onClick={onClick}
      onKeyUp={(e: React.KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
    />
  );
};
