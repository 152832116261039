import { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux';

import {
  CustomButton,
  PageTransitionWrapper,
  StickyPanel,
} from 'src/components';
import { Box, Typography } from '@mui/material';
import { BackButton } from './components/BackButton';
import { Subheader } from './components/Subheader';

import {
  confirmCrucialRequirements,
  setCrucialRequirement,
  userSelectors,
} from 'src/redux/user';

import {
  getUserMissingRequirementCatagories,
  getUserMissingRequirementsByCategory,
} from 'src/utils';
import { JobOpeningCrucialRequirementCategory } from 'src/types';
import { AppRouteByRequirementCategory } from 'src/constants';
import {
  NONE_OPTION_VALUE,
  RequirementsList,
} from './components/RequirementsList';

const HeaderByCategory: Record<JobOpeningCrucialRequirementCategory, string> = {
  [JobOpeningCrucialRequirementCategory.DomainKnowledge]:
    'Have you worked with any of the following?',
  [JobOpeningCrucialRequirementCategory.Technology]:
    'Have you worked with any of the following?',
  [JobOpeningCrucialRequirementCategory.Education]:
    'What education do you have?',
  [JobOpeningCrucialRequirementCategory.Visa]:
    'Do you have any of the following visas?',
};

export const CrucialRequirements: React.FC<{
  category: JobOpeningCrucialRequirementCategory;
}> = ({ category }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userData = useAppSelector(userSelectors.getUserData);
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const crucialRequirements = useAppSelector(
    userSelectors.getUserCrucialRequirements,
  );

  const missingCrucialRequirements = useMemo(
    () => getUserMissingRequirementsByCategory(userData),
    [userData],
  );
  const missingCrucialRequirementCategories = useMemo(
    () => getUserMissingRequirementCatagories(userData),
    [userData],
  );

  const handleConfirm = useCallback(() => {
    if (
      category === missingCrucialRequirementCategories.at(-1) ||
      missingCrucialRequirementCategories.length === 1
    ) {
      dispatch(
        confirmCrucialRequirements({
          additionalSkills: crucialRequirements.technology.filter(
            (e) => e !== NONE_OPTION_VALUE,
          ),
          educations: crucialRequirements.education.filter(
            (e) => e !== NONE_OPTION_VALUE,
          ),
          domains: crucialRequirements.domainKnowledge.filter(
            (e) => e !== NONE_OPTION_VALUE,
          ),
          visas: crucialRequirements.visa.filter(
            (e) => e !== NONE_OPTION_VALUE,
          ),
        }),
      );
    } else {
      const nextCategoryIdx =
        missingCrucialRequirementCategories.findIndex(
          (reqCategory) => reqCategory === category,
        ) + 1;
      const nextCategory = missingCrucialRequirementCategories[nextCategoryIdx];

      navigate(`/${AppRouteByRequirementCategory[nextCategory]}`);
    }
  }, [crucialRequirements, missingCrucialRequirementCategories]);

  const handleChange = useCallback(
    (
      category: JobOpeningCrucialRequirementCategory,
      value: string,
      isAdd: boolean,
    ) => {
      let nextValue = crucialRequirements;

      if (
        [
          JobOpeningCrucialRequirementCategory.Technology,
          JobOpeningCrucialRequirementCategory.DomainKnowledge,
        ].includes(category)
      ) {
        nextValue = {
          ...nextValue,
          [JobOpeningCrucialRequirementCategory.Technology]:
            isAdd && value === NONE_OPTION_VALUE
              ? []
              : crucialRequirements[
                  JobOpeningCrucialRequirementCategory.Technology
                ].filter((e) => e !== NONE_OPTION_VALUE),
          [JobOpeningCrucialRequirementCategory.DomainKnowledge]:
            isAdd && value === NONE_OPTION_VALUE
              ? []
              : crucialRequirements[
                  JobOpeningCrucialRequirementCategory.DomainKnowledge
                ].filter((e) => e !== NONE_OPTION_VALUE),
        };
      } else {
        nextValue = {
          ...nextValue,
          [category]:
            isAdd && value === NONE_OPTION_VALUE
              ? []
              : crucialRequirements[category].filter(
                  (e) => e !== NONE_OPTION_VALUE,
                ),
        };
      }

      dispatch(
        setCrucialRequirement({
          ...nextValue,
          [category]: isAdd
            ? [...nextValue[category], value]
            : nextValue[category].filter((e) => e !== value),
        }),
      );
    },
    [crucialRequirements],
  );

  const options = useMemo(() => {
    if (category === JobOpeningCrucialRequirementCategory.Technology) {
      return uniqBy(
        (
          [] as Array<{
            name: string;
            category: JobOpeningCrucialRequirementCategory;
          }>
        )
          .concat(
            missingCrucialRequirements[
              JobOpeningCrucialRequirementCategory.Technology
            ].map((e) => ({
              name: e,
              category: JobOpeningCrucialRequirementCategory.Technology,
            })),
          )
          .concat(
            missingCrucialRequirements[
              JobOpeningCrucialRequirementCategory.DomainKnowledge
            ].map((e) => ({
              name: e,
              category: JobOpeningCrucialRequirementCategory.DomainKnowledge,
            })),
          ),
        'name',
      );
    }

    return uniqBy(
      missingCrucialRequirements[category].map((e) => ({
        name: e,
        category,
      })),
      'name',
    );
  }, [missingCrucialRequirements, category]);

  const selectedRequirements = useMemo(() => {
    if (category === JobOpeningCrucialRequirementCategory.Technology) {
      return ([] as string[])
        .concat(
          crucialRequirements[JobOpeningCrucialRequirementCategory.Technology],
        )
        .concat(
          crucialRequirements[
            JobOpeningCrucialRequirementCategory.DomainKnowledge
          ],
        );
    }

    return crucialRequirements[category];
  }, [crucialRequirements, category]);

  const isDisabled = useMemo(() => {
    if (
      [
        JobOpeningCrucialRequirementCategory.Technology,
        JobOpeningCrucialRequirementCategory.DomainKnowledge,
      ].includes(category)
    ) {
      return (
        !crucialRequirements[JobOpeningCrucialRequirementCategory.Technology]
          .length &&
        !crucialRequirements[
          JobOpeningCrucialRequirementCategory.DomainKnowledge
        ].length
      );
    }

    return !crucialRequirements[category].length;
  }, [category, crucialRequirements]);

  return (
    <PageTransitionWrapper>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex">
          <BackButton
            category={category}
            missingCrucialRequirementCategories={
              missingCrucialRequirementCategories
            }
          />
          <Typography variant="h2" marginBottom={0}>
            {HeaderByCategory[category]}
          </Typography>
        </Box>

        <Subheader category={category} />

        <RequirementsList
          requirements={options}
          selectedRequirements={selectedRequirements}
          handleChange={handleChange}
        />

        <StickyPanel>
          <CustomButton
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClick={handleConfirm}
            label="Confirm"
          />
        </StickyPanel>
      </Box>
    </PageTransitionWrapper>
  );
};
