import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { JobOpeningCrucialRequirementCategory } from '@types';

export const NONE_OPTION_VALUE = 'none';
export const NONE_OPTION_LABEL = 'None of the above';

export const RequirementItem: React.FC<{
  category: JobOpeningCrucialRequirementCategory;
  label: string;
  value: string;
  isSelected: boolean;
  handleChange: (
    category: JobOpeningCrucialRequirementCategory,
    value: string,
    isAdd: boolean,
  ) => void;
}> = ({ category, label, value, isSelected, handleChange }) => {
  const onClick = () => handleChange(category, value, !isSelected);

  return (
    <Box
      sx={(theme) => ({
        cursor: 'pointer',
        background: isSelected
          ? theme.palette.highlight.accent
          : theme.palette.highlight.actionable,
        padding: '16px 24px',
      })}
      display="flex"
      gap={1}
      onClick={onClick}
    >
      <Typography>{label}</Typography>
      {isSelected && (
        <CheckIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />
      )}
    </Box>
  );
};
