import { useMemo } from 'react';
import { useAppSelector } from '../redux';

import { userSelectors } from '../redux/user';

import {
  ISubNavigationStep,
  JobOpeningCrucialRequirementCategory,
} from '../types';
import { APP_ROUTES } from '../constants';
import { getUserMissingRequirementCatagories } from 'src/utils';

export const useCrucialRequirementsNavigationSteps = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const crucialRequirements = useAppSelector(
    userSelectors.getUserCrucialRequirements,
  );

  const crucialRequirementSteps = useMemo(() => {
    const steps: ISubNavigationStep[] = [];

    steps.push({
      label: 'Tell us about yourself',
      route: '',
      isHeader: true,
      isCompleted: true,
    });

    steps.push({
      label: 'Confirm requirements',
      route: '',
      isHeader: true,
      isActive: true,
    });

    const missingCrucialRequirementCategories =
      getUserMissingRequirementCatagories(userData);

    if (
      missingCrucialRequirementCategories.includes(
        JobOpeningCrucialRequirementCategory.DomainKnowledge,
      ) ||
      missingCrucialRequirementCategories.includes(
        JobOpeningCrucialRequirementCategory.Technology,
      )
    ) {
      steps.push({
        label: 'Required skills',
        route: APP_ROUTES.SKILLS_REQUIREMENTS,
        isEditable: !!crucialRequirements.technology.length,
      });
    }

    if (
      missingCrucialRequirementCategories.includes(
        JobOpeningCrucialRequirementCategory.Education,
      )
    ) {
      steps.push({
        label: 'Education',
        route: APP_ROUTES.EDUCATION_REQUIREMENTS,
        isEditable: !!crucialRequirements.education.length,
      });
    }

    if (
      missingCrucialRequirementCategories.includes(
        JobOpeningCrucialRequirementCategory.Visa,
      )
    ) {
      steps.push({
        label: 'Visa',
        route: APP_ROUTES.VISA_REQUIREMENTS,
        isEditable: !!crucialRequirements.visa.length,
      });
    }

    return steps;
  }, [userData, crucialRequirements]);

  return { crucialRequirementSteps };
};
